const state = {

    colorsSet : {},
    paramsSet : {},
    // PARAMS



    defaultColors : {
        wsACCENT          : "#567186",
        wsDARK            : "#567186",
        wsDARKER          : "#283E4F",
        wsDARKLIGHT       : "#94b3c7",
        wsBACKGROUND      : "#DEE8EE",
        wsLIGHTACCENT     : "#D7E5F0",
        wsLIGHTCARD       : "#F2F8FB",
        wsSUCCESS         : "#7AB971",
        wsLIGHTSUCCESS    : "#DDF5CD",
        wsATTENTION       : "#ED8A58",
        wsWARNING         : "#fe4b4b",
        wsDASHBACKGROUND  : "#DEE8EE",
        wsWHITETEXT       : "#ffffff",
        wsWHITE           : "#ffffff",
        wsSUCCESSDARK     : "#44655b",
        wsTEXT            : "#283E4F",
        wsSIDEBARTEXT  : "#567186",
        wsHEADER_TEXT : "#567186",

        // Public Site
        wsHEADER_BG : "#ffffff",
        wsHEADER_BUTTON : "#ED8A58",
        wsPAGE_TITLE_BG : "#283E4F",
        wsFOOTER_BG : "#567186",
        wsFOOTER_TEXT : "#567186"

    },
    defaultParams : {
        DARK_HEADER : false,
        HEADER_BUSINESS_TEXT : true,
    }

}

const actions = {

    async EDIT_PERSONALIZATION_CONFIG({rootState,dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`personalization/edit`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body
            }, {root : true}
        );
    },

}

export default {
    namespaced : true,
    actions,
    state
}